<template>
  <div>
    <div class="modal" v-bind:class="{'is-active': isActive}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{formTitle}}</p>
          <button class="delete" aria-label="close"  @click="$emit('close')"></button>
        </header>
        <section class="modal-card-body">
          <DoodleInput field-name="testCaseName" label-text="TestCase Name" type="text" rule="required|min:3|max:1000" v-model="testCase.question" :model="testCase.question"/>
          <DoodleInput field-name="arguments" label-text="Arguments" type="text" rule="max:255" v-model="testCase.arguments" :model="testCase.arguments"/>
          <DoodleTextArea field-name="input" label-text="Standard Input" rule="max:5000" :model="testCase.input" v-model="testCase.input" rows="3"/>
          <DoodleTextArea field-name="expectedAnswer" label-text="Expected Output" :model="testCase.expectedAnswer" rule="required|max:5000" v-model="testCase.expectedAnswer" rows="3"/>
          <DoodleInput field-name="maximumCpuTimeAllowed" label-text="Maximum CPU Time Permitted (in MilliSeconds)" type="number" v-model="testCase.maximumCpuTimeAllowed" :model="testCase.maximumCpuTimeAllowed"/>
          <DoodleInput field-name="maximumMemoryAllowed" label-text="Maximum Memory Permitted (In kilobytes)" type="number" v-model="testCase.maximumMemoryAllowed" :model="testCase.maximumMemoryAllowed"/>
          <div class="columns is-mobile" v-if="autoScore && optionLevelMarking">
            <div class="column is-6">
              <DoodleInput field-name="mark" label-text="Mark/Score" type="text" rule="required|regex:^[-]?[0-9]+$" v-model="testCase.mark" :model="testCase.mark"/>
            </div>
            <div class="column is-6" v-if="enableNegativeMark">
              <DoodleInput field-name="negativeMark" label-text="Negative Mark/Score" type="text" rule="required|regex:^[-]?[0-9]+$" v-model="testCase.negativeMark" :model="testCase.negativeMark"/>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div class="container">
            <div class="level">
              <div class="level-left">
                <button class="button is-success" @click="saveTestCase">Save</button>
                <button class="button" @click="$emit('close')">Cancel</button>
              </div>
              <div class="level-right" v-show="errorMessage">
                <div class="has-text-centered">
                  <font-awesome-icon icon="exclamation-circle" class="is-size-5"/>
                  <span class="has-text-weight-bold error-message">
                        {{errorMessage}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import DoodleTextArea from '../../Utils/DoodleTextArea'
import DoodleInput from '../../Utils/DoodleInput'

export default {
  name: 'guruQuestionTestCaseModal',
  components: { DoodleInput, DoodleTextArea },
  props: ['mode', 'isActive', 'testCase', 'autoScore', 'enableNegativeMark', 'optionLevelMarking', 'caseIndex'],
  data: function () {
    return {
      errorMessage: null
    }
  },
  methods: {
    saveTestCase () {
      this.errorMessage = null
      this.$validator.validateAll()

      if (this.$validator.errors.items.length < 1) {
        if (this.isAdd) {
          this.$emit('save', this.testCase)
        } else {
          this.$emit('update', this.testCase, this.caseIndex)
        }
      } else {
        this.errorMessage = 'Please check the field level errors'
      }
    }
  },
  computed: {
    formTitle () {
      let titlePrefix = ''

      if (this.isAdd) {
        titlePrefix = 'Add'
      } else if (this.isEdit) {
        titlePrefix = 'Edit'
      }

      return titlePrefix + ' Question'
    },
    isAdd () {
      return this.mode === 'add'
    },
    isEdit () {
      return this.mode === 'edit'
    }
  }
}
</script>

<style scoped lang="scss">
  .testCase-options {
    text-align: center;
    min-height: 100%;
  }

  .testCase-panel {
    vertical-align: text-top;
  }
  .testCase-block {
    padding: 5px;
  }
  .panel-button {
    margin-top: -5px;
    border-width: 0px;
    font-size: 0.85em;
  }

  .drag-item {
    border: 10px solid white;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
</style>
