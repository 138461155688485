<template>
  <div>
    <nav class="panel">
      <div class="panel-heading has-text-weight-bold has-text-grey-darker panel-heading-with-action">
        <span>Choices</span>
        <button class="button is-success has-text-white has-text-weight-semibold is-pulled-right panel-button" @click="addChoice">Add Choice</button>
      </div>
      <div class="panel-block has-text-centered is-paddingless">
        <draggable :list="choices" class="list-group has-background-white-ter"
                   ghost-class="ghost">
          <div v-for="(choice, index) in choices" v-bind:key="index" class="drag-item">
            <div class="columns is-mobile is-marginless is-paddingless">
              <div class="column is-11 choice-block">
                <!--<DoodleTextArea :field-name="'choice-'+index" :model="choice.question"
                                v-model="choice.question" rule="required|min:2" rows="2"/>-->
                <div class="field">
                  <div class="control">
                  <textarea :name="'choice-'+(index+1)" v-model="choice.question" :model="choice.question" v-validate="'required|min:2|max:5000'" rows="3"
                            class="textarea input" v-bind:class="{'is-danger': errors.has('choice-'+(index+1)) }"></textarea>
                    <span v-show="errors.has('choice-'+(index+1))" class="help is-danger">{{ errors.first('choice-'+(index+1)) }}</span>
                  </div>
                </div>
                <div class="has-text-left">
                  <label v-if="autoCorrect" class="has-text-weight-semibold" :class="{'has-text-danger': errors.has('correctAnswerOption') }">
                    <input type="radio" name="correctAnswerOption" :value="index" v-model="correctAnswerOption"
                           v-validate="'required'"  v-on:input="$emit('input', parseInt($event.target.value))" v-if="type==='single'" />
                    <input type="checkbox" v-model="choice.isCorrectAnswer" v-if="type==='multiple'"/>
                    &nbsp;is Correct Answer?
                  </label>
                </div>
                <div class="columns is-mobile" v-if="type==='multiple' && autoScore && optionLevelMarking">
                  <div class="column is-6" v-if="choice.isCorrectAnswer">
                    <DoodleInput field-name="mark" label-text="Mark/Score" type="text" rule="required|regex:^[-]?[0-9]+$" v-model="choice.mark" :model="choice.mark"/>
                  </div>
                  <div class="column is-6" v-if="enableNegativeMark">
                    <DoodleInput field-name="negativeMark" label-text="Negative Mark/Score" type="text" rule="required|regex:^[-]?[0-9]+$"
                                 v-model="choice.negativeMark" :model="choice.negativeMark"/>
                  </div>
                </div>
              </div>
              <div class="choice-options column is-1">
              <span class="choice-panel">
              <a @click="removeChoice(choice)"  class="has-text-weight-semibold ">
                <font-awesome-icon icon="trash-alt"  class="has-text-grey-dark"/>
              </a>
              </span>
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </nav>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import DoodleInput from '../../Utils/DoodleInput'
export default {
  name: 'guruQuestionChoices',
  inject: ['$validator'],
  components: { DoodleInput, draggable },
  props: ['type', 'choices', 'autoCorrect', 'autoScore', 'enableNegativeMark', 'optionLevelMarking', 'correctAnswerOption'],
  data: function () {
    return {
      value: null
    }
  },
  methods: {
    addChoice () {
      this.choices.push({ isCorrectAnswer: false, question: '' })
    },
    removeChoice (choice) {
      this.choices.splice(this.choices.indexOf(choice), 1)
    }
  },
  watch: {
    model () {
      if (this.value !== this.correctAnswerOption) {
        this.value = this.correctAnswerOption
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .choice-options {
    text-align: center;
    min-height: 100%;
  }

  .choice-panel {
    vertical-align: text-top;
  }
  .choice-block {
    padding: 5px;
  }
  .panel-button {
    margin-top: -5px;
    border-width: 0px;
    font-size: 0.85em;
  }

  .drag-item {
    border: 10px solid white;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
</style>
