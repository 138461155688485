<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <DoodleGeneralForm :title="formTitle"
                       in-progress-message="Updating Question, Please wait..."
                       success-message="Question Added Successfully"
                       :action-text="formTitle"
                       :form="doodleForm"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       :hide-action="hideAction"
                       @action="addEditQuestion"
                       @close="$refs.sideBar.emitToggle()">
      <div>
        <DoodleSelect label-text="Question Type" :model="doodleForm.questionType"
                      v-model="doodleForm.questionType" field-name="Question Type"
                      :list="questionTypes" display-item="false" display-property="type" @change="onQuestionTypeChange"/>
        <div  v-if="questionTypeSelected">
          <DoodleTextArea field-name="doodleForm.question" label-text="Question"
                          :model="doodleForm.question"
                          v-model="doodleForm.question" rule="required|min:5|max:10000" rows="4" v-if="!isOpenEndedWithDiscloseAtSubmission"/>
          <label class="checkbox has-text-weight-semibold has-text-black option-level-label" v-if="showOptionLevelMarkingCheckbox">
            <input type="checkbox" v-model="doodleForm.optionLevelMarking">
            &nbsp;&nbsp;Score/Mark at <span v-if="isProgramQuestion">Test Cases Level</span> <span v-if="isMultipleChoiceQuestion">Choice Level</span>
          </label>
          <div class="columns is-mobile" v-if="autoScore && !doodleForm.optionLevelMarking && !isOpenEndedQuestion">
            <div class="column is-6">
              <DoodleInput field-name="mark" label-text="Mark/Score" type="text" rule="required|regex:^[-]?[0-9]+$"
                           :model="doodleForm.markForCorrectAnswer" v-model.number="doodleForm.markForCorrectAnswer"/>
            </div>
            <div class="column is-6" v-if="enableNegativeMark">
              <DoodleInput field-name="negativeMark" label-text="Negative Mark/Score" type="text" rule="required|regex:^[-]?[0-9]+$"
                           :model="doodleForm.negativeMark" v-model.number="doodleForm.negativeMark"/>
            </div>
          </div>
          <div class="columns" v-if="isProgramQuestion">
            <div class="column is-6">
              <DoodleSelect label-text="Language" :model="doodleForm.language"
                            v-model="doodleForm.language" :rule="(autoCorrect)?'auto_correct_lang':''" field-name="Language"
                            :list="languages" display-item="false" display-property="displayName" @change="onLanguageChange"/>
            </div>
            <div class="column is-6" v-if="showIndexBox">
              <DoodleSelect label-text="Version" v-model="doodleForm.versionIndex" :model="doodleForm.versionIndex"
                            field-name="Version" :list="doodleForm.language.versions" display-item="true"/>
            </div>
          </div>
          <div class="columns" v-if="isOpenEndedQuestion && !isOpenEndedWithDiscloseAtSubmission">
            <div class="column is-6">
              <DoodleInput field-name="minLength" label-text="Minimum Length" type="number" rule="decimal:2"
                           :model="doodleForm.minLength" v-model.number="doodleForm.minLength"/>
            </div>
            <div class="column is-6">
              <DoodleInput field-name="maxLength" label-text="Maximum Length" type="number" rule="decimal:2"
                           :model="doodleForm.maxLength" v-model.number="doodleForm.maxLength"/>
            </div>
          </div>
          <GuruQuestionChoices :choices="doodleForm.options" :type="choiceType" :auto-correct="autoCorrect" :auto-score="autoScore"
                               :option-level-marking="doodleForm.optionLevelMarking" :enable-negative-mark="enableNegativeMark"
                               :correct-answer-option="doodleForm.correctAnswerOption" v-model="doodleForm.correctAnswerOption"
                               :model="doodleForm.correctAnswerOption" v-if="isChoiceQuestion"/>
          <GuruQuestionTestCases :testCases="doodleForm.testCases" :type="choiceType" :auto-correct="autoCorrect" :auto-score="autoScore"
                               :option-level-marking="doodleForm.optionLevelMarking" :enable-negative-mark="enableNegativeMark" v-if="isProgramQuestion && autoCorrect"/>
          <GuruQuestionTrueOrFalse :correct-answer-option="doodleForm.correctAnswerOption" v-model="doodleForm.correctAnswerOption"
                                   :model="doodleForm.correctAnswerOption" v-if="isYesOrNoQuestion && autoCorrect"/>
          <GuruQuestionOpenEndedWarning v-if="isOpenEndedWithDiscloseAtSubmission"/>
        </div>
      </div>
      <template v-slot:successMessage>
        <div class="has-text-centered">
          <div class="register-thanks" v-if="isAdd">
            Question added!
          </div>
          <div class="register-thanks" v-if="isEdit">
            Question Updated!
          </div>
        </div>
      </template>
    </DoodleGeneralForm>
    <div class="guru-hints" v-if="autoCorrect && (isProgramQuestion || isChoiceQuestion)">
      <span class="has-text-weight-bold is-underlined">Hints:</span> Drag and drop choices/testcases to reorder.
    </div>
  </SideBar>
</template>

<script>
import formMixin from '../../../assets/javascript/form-mixin'
import questionMixin from '../../../assets/javascript/guru-question-mixin'
import DoodleInput from '../../Utils/DoodleInput'
import DoodleGeneralForm from '../../Utils/DoodleGeneralForm'
import SideBar from '../../Utils/SideBar'
import { eventBus } from '../../../assets/javascript/event-bus'
import { GURU_CONSTANTS, GURU_EVENTS, GURU_FORM_STATUS } from '../../../assets/javascript/constants'
import DoodleTextArea from '../../Utils/DoodleTextArea'
import DoodleSelect from '../../Utils/DoodleSelect'
import GuruQuestionChoices from './GuruQuestionChoices'
import GuruQuestionTrueOrFalse from './GuruQuestionTrueOrFalse'
import GuruQuestionOpenEndedWarning from './GuruQuestionOpenEndedWarning'
import GuruQuestionTestCases from './GuruQuestionTestCases'

export default {
  name: 'guruAddEditQuestion',
  mixins: [formMixin, questionMixin],
  inject: ['$validator'],
  components: { GuruQuestionTestCases, GuruQuestionOpenEndedWarning, GuruQuestionTrueOrFalse, GuruQuestionChoices, DoodleSelect, DoodleTextArea, SideBar, DoodleGeneralForm, DoodleInput },
  props: ['mode', 'instituteCode', 'autoCorrect', 'autoScore', 'enableNegativeMark', 'disclose', 'insAssignmentId', 'assQuestionId', 'defaultLanguage', 'versionIndex', 'currentAssociation', 'assignment'],
  data: function () {
    return {
      editMode: false
    }
  },
  created () {
    this.$validator.extend('auto_correct_lang', {
      getMessage: field => 'Please select a language',
      validate: (value, [testProp]) => {
        return value.name !== 'none'
      }
    })
  },
  mounted () {
    if (this.isAdd) {
      this.doodleForm.questionType = this.questionTypes[0]
      if (this.defaultLanguage) {
        this.doodleForm.language = this.defaultLanguage
        this.doodleForm.versionIndex = this.versionIndex
      } else {
        this.doodleForm.language = this.languages[0]
      }
    } else {
      this.loadQuestion()
    }
  },
  methods: {
    addEditQuestion () {
      this.$validator.validateAll()
      if (this.$validator.errors.items.length > 0) {
        return
      }

      if (this.isChoiceQuestion && this.doodleForm.options.length < 2) {
        this.doodleForm.errorMessage = 'Please add at least 2 choices.'
        return
      }

      if (this.autoCorrect) {
        if (this.isMultipleChoiceQuestion) {
          let hasAtLeastOneCorrectAnswer = false

          for (let opt in this.doodleForm.options) {
            if (this.doodleForm.options[opt].isCorrectAnswer === true) {
              hasAtLeastOneCorrectAnswer = true
              break
            }
          }

          if (!hasAtLeastOneCorrectAnswer) {
            this.doodleForm.errorMessage = 'Please mark at least one choice as correct answer.'
            return
          }
        } else if (this.isProgramQuestion && this.doodleForm.testCases.length < 1) {
          this.doodleForm.errorMessage = 'Please add at least 1 test case.'
          return
        }
      }
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/assignment/addQuestion',
        data: JSON.stringify({
          instituteCode: this.instituteCode,
          assignmentId: this.insAssignmentId,
          requestJson: this.doodleForm
        }),
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        contentType: 'application/json',
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          if (this.isAdd) {
            eventBus.$emit(GURU_EVENTS.ADD_QUESTION_TO_ASSIGNEMNT, data.question)
          } else if (this.isEdit) {
            eventBus.$emit(GURU_EVENTS.UPDATE_QUESTION_IN_ASSIGNMENT, data.question)
          }
        },
        jdaEvent: (this.isAdd) ? 'guru-add-question-to-assignment' : 'guru-update-question-in-assignment',
        jdaLabel: ''
      })
    },
    loadQuestion () {
      this.doodleForm.assQuestionId = this.assQuestionId
      this.loadQuestionMixin(() => {
        if (this.isYesOrNoQuestion && this.doodleForm.correctAnswerOption === true) {
          this.doodleForm.correctAnswerOption = 1
        } else {
          this.doodleForm.correctAnswerOption = 0
        }

        if (this.isSingleChoiceQuestion) {
          this.doodleForm.correctAnswerOption = null
          for (let opt in this.doodleForm.options) {
            if (this.doodleForm.options[opt].isCorrectAnswer === true) {
              this.doodleForm.correctAnswerOption = opt
              break
            }
          }
        }
      }, {})
    },
    onQuestionTypeChange () {
      this.doodleForm.optionLevelMarking = false
    },
    onLanguageChange () {
      if (this.doodleForm.language.versions.length > 0) {
        this.doodleForm.versionIndex = 0
      }
    }
  },
  computed: {
    formTitle () {
      let titlePrefix = ''

      if (this.localMode === GURU_FORM_STATUS.ADD) {
        titlePrefix = 'Add'
      } else if (this.localMode === GURU_FORM_STATUS.EDIT) {
        titlePrefix = 'Update'
      }

      return titlePrefix + ' Question'
    },
    localMode () {
      if (this.editMode) {
        return GURU_FORM_STATUS.EDIT
      }

      return this.mode
    },
    hideAction () {
      return !this.questionTypeSelected || this.isOpenEndedWithDiscloseAtSubmission
    },
    questionTypeSelected () {
      return this.doodleForm.questionType !== GURU_CONSTANTS.QUESTION_TYPES[0]
    },
    isOpenEndedWithDiscloseAtSubmission () {
      return this.isOpenEndedQuestion && this.disclose === GURU_CONSTANTS.DISCLOSE_OPTIONS[1]
    },
    choiceType () {
      if (this.isSingleChoiceQuestion) {
        return 'single'
      } else if (this.isMultipleChoiceQuestion) {
        return 'multiple'
      } else {
        return 'none'
      }
    },
    showOptionLevelMarkingCheckbox () {
      return this.autoScore && (this.isMultipleChoiceQuestion || this.isProgramQuestion)
    },
    isAdd () {
      return this.mode === GURU_FORM_STATUS.ADD
    },
    isEdit () {
      return this.mode === GURU_FORM_STATUS.EDIT
    },
    showIndexBox () {
      return this.doodleForm.language && this.doodleForm.language.versions &&
        this.doodleForm.language.versions.length > 0 && this.doodleForm.versionIndex != null
    }
  }
}
</script>

<style scoped lang="scss">
  .auto-score-note {
    font-size: 0.85em;
  }

  .option-level-label {
   margin-bottom: 0.5em;
  }
</style>
