<template>
  <div class="msg has-text-weight-semibold has-text-centered">
    <font-awesome-icon icon="exclamation-circle" /> Open ended question can not be included in an Assignment with disclose type - "Auto Disclose at the time of submission". To include open ended question, please change the disclose type.
  </div>
</template>

<script>
export default {
  name: 'guruQuestionOpenEndedWarning'
}
</script>
<style scoped lang="scss">
  .msg {
    background-color: #f5f5f5;
    border-radius: 6px;
    color: #586e75;
    padding: 1em;
    font-size: 0.9em;
  }
</style>
