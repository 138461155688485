<template>
  <div>
    <nav class="panel">
      <div class="panel-heading has-text-weight-bold has-text-grey-darker panel-heading-with-action">
        <span>Test Cases</span>
        <button class="button is-success has-text-white has-text-weight-semibold is-pulled-right panel-button" @click="addTestCase">Add TestCase</button>
      </div>
      <div class="panel-block is-paddingless">
        <draggable :list="testCases" class="list-group has-background-white-ter"
                   ghost-class="ghost">
          <div v-for="(testCase, index) in testCases" v-bind:key="index" class="drag-item">
            <div class="columns is-mobile is-marginless is-paddingless">
              <div class="column is-10">
                {{index+1}}. {{testCase.question}}
              </div>
              <div class="testCase-options column is-2">
              <span>
                <a @click="editTestCase(testCase)"  class="has-text-weight-semibold ">
                <font-awesome-icon icon="pencil-alt"  class="has-text-grey-dark"/>
              </a>&nbsp;&nbsp;&nbsp;
              <a @click="removeTestCase(testCase)"  class="has-text-weight-semibold ">
                <font-awesome-icon icon="trash-alt"  class="has-text-grey-dark"/>
              </a>
              </span>
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </nav>
    <GuruQuestionTestCaseModal :mode="testCaseMode" :case-index="caseIndex" :test-case="testCase" :is-active="showTestCaseModal"
                               :auto-score="autoScore" :enable-negative-mark="enableNegativeMark" :option-level-marking="optionLevelMarking"
                               @close="closeTestCaseModal" @save="saveTestCase" @update="updateTestCase" v-if="showTestCaseModal"/>
  </div>
</template>

<script>

import draggable from 'vuedraggable'
import GuruQuestionTestCaseModal from './GuruQuestionTestCaseModal'
export default {
  name: 'guruQuestionTestCases',
  inject: ['$validator'],
  components: { GuruQuestionTestCaseModal, draggable },
  props: ['type', 'testCases', 'autoCorrect', 'autoScore', 'enableNegativeMark', 'optionLevelMarking'],
  data: function () {
    return {
      showTestCaseModal: false,
      testCaseMode: null,
      testCase: {},
      caseIndex: -1
    }
  },
  methods: {
    addTestCase () {
      this.showTestCaseModal = true
      this.testCaseMode = 'add'
      this.testCase = {
        question: '',
        input: '',
        arguments: '',
        maximumCpuTimeAllowed: '',
        maximumMemoryAllowed: '',
        expectedAnswer: ''
      }
      this.caseIndex = -1
    },
    editTestCase (testCase) {
      this.testCaseMode = 'edit'
      this.showTestCaseModal = true
      this.testCase = window._.cloneDeep(testCase)
      this.caseIndex = this.testCases.indexOf(testCase)
    },
    removeTestCase (testCase) {
      this.testCases.splice(this.testCases.indexOf(testCase), 1)
    },
    closeTestCaseModal () {
      this.showTestCaseModal = false
    },
    saveTestCase (testCase) {
      this.testCases.push(testCase)
      this.showTestCaseModal = false
    },
    updateTestCase (testCase, caseIndex) {
      this.testCases.splice(caseIndex, 1, testCase)
      this.showTestCaseModal = false
    }
  }
}
</script>

<style scoped lang="scss">
  .testCase-options {
    text-align: center;
    min-height: 100%;
  }
  .panel-button {
    margin-top: -5px;
    border-width: 0px;
    font-size: 0.85em;
  }

  .drag-item {
    border: 10px solid white;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
</style>
