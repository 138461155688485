<template>
  <div>
    <label class="has-text-weight-bold">Correct Answer: </label> &nbsp;&nbsp;
    <label class="has-text-weight-bold" :class="{'has-text-danger': errors.has('correctAnswer') }">
      <input type="radio" name="correctAnswer" value="1" v-model="value" v-validate="'required'" v-on:input="$emit('input', $event.target.value)"/>
      &nbsp;&nbsp;True
    </label> &nbsp;&nbsp;
    <label class="has-text-weight-bold"  :class="{'has-text-danger': errors.has('correctAnswer') }">
      <input type="radio" name="correctAnswer" value="0" v-model="value" v-on:input="$emit('input', $event.target.value)"/>
      &nbsp;&nbsp;False
    </label>
    <span v-show="errors.has('correctAnswer')" class="help is-danger">{{ errors.first('correctAnswer') }}</span>
  </div>
</template>

<script>
export default {
  name: 'guruQuestionTrueOrFalse',
  inject: ['$validator'],
  props: ['correctAnswerOption'],
  mounted () {
    this.value = this.correctAnswerOption
  },
  data: function () {
    return {
      value: null
    }
  },
  watch: {
    correctAnswerOption () {
      if (this.value !== this.correctAnswerOption) {
        this.value = this.correctAnswerOption
      }
    }
  }
}
</script>
